<template>
  <div>
    <v-row v-if="user.account_type !== 'free'">
      <v-col cols="12" md="5" offset-md="1">
        <v-card class="px-7 pb-6 rounded-xl" flat>
          <v-toolbar class="d-flex justify-center font-weight-bold" flat>
            Branding
          </v-toolbar>
          <small class="grey--text d-block mb-5">
            Customize the appearance of the recording links
          </small>
          <div class="text-left" v-for="(label, key) in colorFields" :key="key">
            <v-card-text class="black--text pa-0">{{ label }}</v-card-text>
            <small class="grey--text">{{ descriptions[key] }}</small>
            <v-text-field
              solo
              dense
              flat
              background-color="secondary"
              prepend-inner-icon=""
              class="text-grey"
              v-model="form[key]"
              readonly
              @click="openDialog(key)"
            >
              <template v-slot:prepend-inner>
                <div
                  class="pa-5 ml-n3 mr-2 color-field"
                  :style="{ background: form[key] || defaultColors[key] }"
                ></div>
              </template>
            </v-text-field>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="error"
              @click="resetBrandColors"
              :loading="resetLoading"
              class="capitalize px-5 mr-4"
              outlined
              rounded
            >
              <small>reset default</small>
            </v-btn>
            <v-btn
              @click="brandingUpdate"
              :loading="loadBranding"
              class="capitalize primary px-5"
              rounded
              text
            >
              <small>save changes</small>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="5">
        <v-card class="px-7 pb-6 rounded-xl" flat>
          <v-toolbar class="d-flex justify-center font-weight-bold" flat>
            Custom Domain Settings
          </v-toolbar>
          <small class="d-block" style="font-size: 10px">
            Here you can customize your recording URL, Request link and share
            link to a custom domain. This gives you brand recognition when
            sharing or requesting video from customers.
          </small>
          <v-toolbar class="mt-2 mb-n4 d-flex justify-center" flat>
            <strong>Active Domain</strong>
          </v-toolbar>
          <v-chip
            class="secondary darken-1 mt-n2 primary--text"
            style="min-width: 150px"
            small
          >
            {{ custom_domains.name }}
          </v-chip>
          <v-form lazy-validation ref="domain_settings" class="text-left">
            <div>
              <label class="tagline">
                Tagline
                <v-chip class="secondary darken-4 rounded-chip pointer">
                  ?
                </v-chip>
                <span class="toolip">
                  The descriptive word phrases that are briefly converying what
                  your business is in a nutshell.
                </span>
              </label>
              <v-text-field
                solo
                flat
                dense
                background-color="secondary"
                placeholder=""
                type="text"
                v-model="domain_form.tagline"
                class="mt-1"
                style="width: 100%"
              ></v-text-field>
            </div>
            <div>
              <v-row no-gutters>
                <v-col cols="7" sm="7" md="7">
                  <div style="text-align: left">
                    <label class="d-flex mb-2">
                      <span class="mt-1 settings">
                        Favicon Settings
                        <v-chip class="secondary darken-4 rounded-chip pointer">
                          ?
                        </v-chip>
                        <span class="toolip">
                          The icon associated with your particular domain,
                          typically displayed in the address bard of a browser
                          or next to the site's name
                        </span>
                      </span>
                    </label>
                    <div v-if="custom_domains.favicon_url == null">
                      <img
                        @click="selectFile"
                        :src="upload_img"
                        height="60"
                        width="200"
                        alt=""
                        class="pointer"
                      />
                      <br />
                      <v-file-input
                        id="fileUpload"
                        style="position: absolute; opacity: 0"
                        class="mb-n5 mt-n4"
                        prepend-icon=""
                        prepend-inner-icon="mdi-folder-open-outline"
                        label="Upload your icon file"
                        placeholder="Upload your icon file"
                        v-model="domain_form.favicon"
                      ></v-file-input>
                    </div>
                    <div
                      v-if="custom_domains.favicon_url !== null"
                      class="d-flex current-favicon"
                    >
                      <img
                        :src="custom_domains.favicon_url"
                        height="45"
                        width="45"
                        alt="Current Favicon Icon"
                        class="my-0 current-fav rounded-xl"
                      />
                      <v-icon
                        small
                        color="error"
                        class="remove-icon"
                        @click="removeFavicon"
                      >
                        mdi-close
                      </v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-row no-gutters>
                <v-col cols="12">
                  <div>
                    <label class="d-flex mb-2 mt-3">
                      <span class="mt-1 domain-logo">
                        Logo
                        <v-chip class="secondary darken-4 rounded-chip pointer">
                          ?
                        </v-chip>
                        <span class="toolip">
                          Default logo on your recording pages, request links
                          and video playing links.
                        </span>
                      </span>
                    </label>
                    <div
                      v-if="custom_domains.domain_logo_url === null"
                      class="upload-domain-logo secondary pa-4 rounded-xl"
                    >
                      <label>
                        <small style="font-size: 11px">
                          Upload 842 x 292 design for better display
                        </small>
                        <br />
                        <v-btn
                          :disabled="loading"
                          :loading="loading"
                          @click="selectDomainLogoFile"
                          color="blue-grey"
                          class="ma-2 capitalize white--text"
                          small
                          rounded
                        >
                          Select Image
                          <v-icon right dark> mdi-cloud-upload </v-icon>
                        </v-btn>
                      </label>
                      <v-file-input
                        id="domainLogoFileUpload"
                        style="position: absolute; opacity: 0"
                        class="mb-n5 mt-n4"
                        prepend-icon=""
                        prepend-inner-icon="mdi-folder-open-outline"
                        label="Upload your icon file"
                        placeholder="Upload your domain logo"
                        v-model="domain_form.domain_logo"
                      ></v-file-input>
                    </div>
                    <div
                      v-if="custom_domains.domain_logo_url !== null"
                      class="d-flex current-favicon"
                    >
                      <img
                        :src="custom_domains.domain_logo_url"
                        alt="Current Domain Logo"
                        class="my-0 current-fav current-logo rounded-xl"
                      />
                      <v-icon
                        small
                        color="error"
                        class="remove-icon"
                        @click="removeLogo"
                      >
                        mdi-close
                      </v-icon>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="saveDomainSettings"
                :loading="loading"
                class="capitalize primary px-5 mt-3"
                rounded
                text
              >
                <small>save changes</small>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="isKonveyDomainDialog" width="auto" persistent>
      <v-card width="700">
        <div v-if="user.account_type == 'free'">
          <v-card-text class="text-left pt-8">
            <span>
              <strong>Note: </strong>
              You cannot use this feature, please upgrade your account!
            </span>
          </v-card-text>
          <v-card-actions class="d-flex justify-center py-8">
            <v-btn
              small
              style="width: 200px"
              to="/account-upgrade"
              class="primary rounded-xl capitalize"
            >
              Upgrade</v-btn
            >
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text class="text-left pt-8">
            <span>
              <strong>Note: </strong>
              To take advantage of this feature, please ensure you mark your
              custom domain as default. This will enable you to seamlessly
              integrate your brand's identity into your domain.
            </span>
          </v-card-text>
          <v-card-actions class="d-flex justify-center py-8">
            <v-btn
              small
              style="width: 200px"
              @click="markDomainAsDefaultBtn"
              class="primary rounded-xl capitalize"
            >
              Mark domain as default</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="300">
      <v-card class="pa-5">
        <v-row justify="end">
          <v-icon @click="dialog = false" color="error" size="12">
            mdi-close
          </v-icon>
        </v-row>
        <v-toolbar flat class="py-n2 my-n4 d-flex justify-center">
          Select a color code
        </v-toolbar>
        <v-color-picker v-model="selectedColor"></v-color-picker>
        <v-card-actions>
          <v-btn
            block
            small
            class="primary rounded-xl capitalize block"
            @click="applyColor"
          >
            Done making changes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  components: {},
  name: "RecordSettings",
  data() {
    return {
      selectedColorKey: "",
      selectedColor: "",
      form: {
        section_bg_color: "",
        accent_color: "",
        button_text_color: "",
      },
      defaultColors: {
        section_bg_color: "#E9F0FA",
        accent_color: "#2764F2",
        button_text_color: "#ffffff",
      },
      colorFields: {
        section_bg_color: "Brand Color",
        accent_color: "Accent Color",
        button_text_color: "Button Text Color",
      },
      descriptions: {
        section_bg_color: "Color used for recording page background",
        accent_color: "Color used for form buttons",
        button_text_color: "Color used for button text",
      },
      toast: new ResponseHelper(),
      loading: false,
      loadBranding: false,
      resetLoading: false,
      personal_url: false,
      dialog: false,
      isKonveyDomainDialog: false,
      brand: false,
      example_url: `e.g${process.env.VUE_APP_URL}/johndoe`,
      custom_domains: [],
      loadingDomains: false,
      types: ["hex", "hexa", "rgba", "hsla", "hsva"],
      type: "hex",
      hex: "#FFFFFF",
      hexa: "#FF00FFFF",
      rgba: { r: 255, g: 0, b: 255, a: 1 },
      hsla: { h: 300, s: 1, l: 0.5, a: 1 },
      hsva: { h: 300, s: 1, v: 1, a: 1 },
      upload_img: require("@/assets/upload-favicon.png"),
      domain_form: {
        tagline: "",
      },
    };
  },
  watch: {
    showColor(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.updateColor();
      }
    },
  },
  created() {
    this.form = Object.assign({}, this.settings);
    this.getDomains();
    // this.getSettings();
    this.getCustomDomainDtls();
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings",
      user: "auth/user",
      default_domain: "domain/default_domain",
    }),
    domains() {
      if (Array.isArray(this.custom_domains)) {
        return this.custom_domains.map((x) => {
          return {
            name: x.name,
            id: x.id,
          };
        });
      }
      return [];
    },
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },
    showColor() {
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },
  },
  methods: {
    ...mapActions({
      delete_settings: "settings/delete_settings",
      settings_update: "settings/settings_update",
      get_categories: "settings/get_categories",
      get_settings: "settings/get_settings",
      get_domains: "domain/get_domains",
      get_single_domain: "domain/get_single_domain",
      mark_as_default: "domain/mark_as_default",
      upload_favicon: "domain/upload_favicon",
    }),
    async getSettings(customDomainId) {
      try {
        // noinspection JSCheckFunctionSignatures
        const response = await this.get_settings(customDomainId);
        this.form = Object.assign({}, response.data);
        setTimeout(() => {
          this.domains.length === 0 ||
          this.form["enable_screen_recording"] === "no"
            ? (this.form["enable_screen_recording"] = "no")
            : (this.form["enable_screen_recording"] = "yes");
        }, 500);
      } catch (e) {
        this.toast.sendError(e);
      }
    },
    // async settingsUpdate() {
    //   this.loading = true;
    //   try {
    //     const response = await this.settings_update(this.form);
    //     if (this.form["enable_screen_recording"] === "no") {
    //       const item = {
    //         name: "dashboard.konvey.app",
    //         id: "konvey-domain-id",
    //       };
    //       await this.markAsDefault(item);
    //     } else {
    //       await this.getDomains();
    //     }
    //     this.loading = false;
    //     if (!this.personal_url) {
    //       this.toast.sendSuccess(response);
    //     }
    //   } catch (e) {
    //     this.loading = false;
    //     this.toast.sendError(e);
    //   }
    // },
    async brandingUpdate() {
      this.loadBranding = true;
      try {
        this.form.custom_domain_id = this.custom_domains.id;
        const response = await this.settings_update(this.form);
        this.toast.sendSuccess(response);
      } catch (e) {
        this.toast.sendError(e);
      } finally {
        this.loadBranding = false;
        window.location.reload();
      }
    },
    async resetBrandColors() {
      this.resetLoading = true;
      try {
        const userId = this.user.id;
        const response = await this.delete_settings(userId);
        this.toast.sendSuccess(response);
      } catch (e) {
        this.toast.sendError(e);
      } finally {
        this.form = {};
        this.resetLoading = false;
        window.location.reload();
      }
    },
    viewCustomLink() {
      this.personal_url = true;
      if (this.default_domain.name) {
        const host = this.default_domain.name;
        const url =
          process.env.NODE_ENV === "production"
            ? `https://${host}/record`
            : `http://${host}/record`;
        window.open(url);
      } else {
        this.$toast.error(
          "You don't have custom domain or have not set one as default"
        );
      }
    },
    async getDomains() {
      try {
        const isCustomDomain =
          localStorage.getItem("is_custom_domain") === "true";

        if (isCustomDomain) {
          // const response = await this.get_domains();
          // this.custom_domains = response.data[0];

          const response = this.default_domain;
          this.custom_domains = response;

          this.domain_form.tagline =
            this.custom_domains.tagline !== null
              ? this.custom_domains.tagline
              : "";
          // load the settings
          this.getSettings(response.id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async selectedDomain() {
      const item = this.custom_domains.find(
        (x) => x.id === this.default_domain.id
      );
      await this.markAsDefault(item);
    },
    async markAsDefault(item) {
      this.loadingDomains = true;
      try {
        const response = await this.mark_as_default(item);
        this.loadingDomains = false;
        if (this.form["enable_screen_recording"] === "yes") {
          this.toast.sendSuccess(response);
        }
        await this.getDomains();
      } catch (e) {
        this.loadingDomains = false;
        this.toast.sendError(e);
      }
    },
    openDialog(key) {
      this.selectedColorKey = key;
      this.selectedColor = this.form[key] || "#ffffff";
      this.dialog = true;
    },
    applyColor() {
      this.$set(this.form, this.selectedColorKey, this.selectedColor);
      this.dialog = false;
    },

    updateColor() {
      this.brand
        ? (this.form.brand_color = this.showColor)
        : (this.form.accent_color = this.showColor);
    },
    async saveDomainSettings() {
      this.loading = true;
      try {
        // Custom domain ID
        const dId = this.custom_domains.id;

        let formData = new FormData();
        formData.append("favicon", this.domain_form.favicon);
        formData.append("domain_logo", this.domain_form.domain_logo);
        formData.append("tagline", this.domain_form.tagline);
        formData.append("domain_id", dId);
        const response = await this.upload_favicon(formData);
        this.loading = false;
        this.toast.sendSuccess(response);

        const get_domain_response = await this.get_single_domain(dId);
        this.custom_domains = get_domain_response.data;
      } catch (e) {
        this.loading = false;
        this.toast.sendError(e);
      }
    },
    markDomainAsDefaultBtn() {
      this.$router.push("/custom-domain");
    },
    getCustomDomainDtls() {
      try {
        const isCustomDomain =
          localStorage.getItem("is_custom_domain") === "true";

        if (!isCustomDomain) {
          this.isKonveyDomainDialog = true;
        }
      } catch (error) {
        console.error("Error retrieving custom domain details: ", error);
      }
    },
    selectFile() {
      document.querySelector("#fileUpload").click();
    },
    selectDomainLogoFile() {
      document.querySelector("#domainLogoFileUpload").click();
    },
    removeFavicon() {
      this.custom_domains.favicon_url = null;
      this.domain_form.favicon = null;
    },
    removeLogo() {
      this.custom_domains.domain_logo_url = null;
      this.domain_form.domain_logo = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-grey >>> .v-text-field__slot input {
  color: grey;
  font-size: 12px;
}
.dotted-border {
  border: 1px dashed #6257fe;
}

.horizontal-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.rounded-chip {
  padding: 4px 5px;
  width: 17px !important;
  height: 17px !important;
  border-radius: 100%;
}
.rounded-chip:hover {
  cursor: help;
  position: relative;
}
.toolip {
  display: none;
}
.tagline {
  float: left;
}
.settings:hover .toolip {
  border: #c0c0c0 1px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 999999999;
  background: #ffffff;
  left: 0;
  margin: 10px;
  width: 250px;
  position: absolute;
  top: 290px;
  text-decoration: none;
  color: #000000;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
}

.tagline:hover .toolip {
  border: #c0c0c0 1px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 999999999;
  background: #ffffff;
  left: 0;
  margin: 10px;
  width: 250px;
  position: absolute;
  bottom: 370px;
  text-decoration: none;
  color: #000000;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
}

.domain-logo:hover .toolip {
  border: #c0c0c0 1px dotted;
  padding: 5px 20px 5px 5px;
  display: block;
  z-index: 999999999;
  background: #ffffff;
  left: 0;
  margin: 10px;
  width: 250px;
  position: absolute;
  bottom: 150px;
  text-decoration: none;
  color: #000000;
  font-size: 13px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-align: center;
}

.current-fav {
  border-radius: 8% !important;
  box-shadow: 0px 0px 3px #eee;
}
.upload-domain-logo {
  /* width: 200px !important; */
  text-align: center;
  border: 1px dashed blue !important;
}
.current-favicon {
  position: relative !important;
  display: inline-block !important;
  width: fit-content !important;
  img {
    box-shadow: 0px 0px 2px gray !important;
  }
  .current-logo {
    width: 65px !important;
    height: 60px !important;
    object-fit: contain !important;
    display: block !important;
    margin: auto !important;
  }
}
.remove-icon {
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  cursor: pointer !important;
  background: white !important;
  border-radius: 50% !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2) !important;
}

.color-field {
  box-shadow: 0 0 3px #ccc !important;
}
</style>
